











































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import FgrUserData from './c-frg-data/Index.vue';
import KatUserData from './c-kat-data/Index.vue';
import AbpUserData from './c-abp-data/Index.vue';
import RegionUserData from './c-region-data/Index.vue';
import KatoUserData from './c-kato-data/Index.vue';
import GuUserData from './c-gu-data/Index.vue';
import KgkpUserData from './c-kgkp-data/Index.vue';
import GuUsers from './c-gu-users/Index.vue';
import MsuUserData from './c-msu-data/Index.vue';
import ModulesUserData from './c-modules-data/Index.vue';
import './user-management.scss';
import {IKeyCloakUser, IUserOperations} from './user-management-int';
import UtilUI from './util-ui';
import WorkplaceUserData from "@/modules/user-management/c-workplace-data/Index.vue";
import WorkplaceUserGkkpData from "@/modules/user-management/c-workplace-gkkp-data/Index.vue";
import WorkplaceUsers from "@/modules/user-management/c-workplace-users/Index.vue";
import BaseUserLink from './base-user-link';
import store from "@/services/store";
import axios from "axios";

@Component({
    components: {
        FgrUserData,
        AbpUserData,
        RegionUserData,
        GuUserData,
        MsuUserData,
        KatUserData,
        KatoUserData,
        ModulesUserData,
        GuUsers,
        KgkpUserData,
        WorkplaceUserData,
        WorkplaceUserGkkpData,
        WorkplaceUsers
    }
})

export default class UserManagement extends UtilUI {
    private userFilter = '';
    private userList: any = [];
    private selectedUser: IKeyCloakUser | null = null;
    private sourceUser: IKeyCloakUser | null = null;
    private activeTab = 0;
    private oblRegion = this.$store.state._instanceCode;
    private operations: any = [];
    private realm_user_count = 0;
    private isReduceUser: boolean = false;
    private isSupportUser: boolean = false;

    @Watch('selectedUser')
    async onUserChanged(value: IKeyCloakUser, oldValue: IKeyCloakUser) {
        await this.get_user_is_support(value);
        await this.fetchUserReduce(value.id);
    }
async fetchUserReduce(userId: string) {
    try {
      const response = await axios.get(`/api/um/user-reduce/${userId}`);
      this.isReduceUser = response.data && response.data.length > 0;
    } catch (error) {
      console.error("Ошибка при получении настроек:", error);
    }
  }

  async saveUserReduce(user: IKeyCloakUser) {
    if (!this.selectedUser) return;
      const saveData = {
            user_id: user.id,
            vals: [
                {
                    item: this.isReduceUser
                }
            ]
        };
        try {
            await fetch('/api/um/save-user-reduce', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(saveData)
            });
        } catch (error) {
            this.makeToast('danger', 'Ошибка сохранения данных: ' + user.id, error.toString());
        }
}


    async get_user_is_support(user: IKeyCloakUser) {
        const response = await fetch('/api/um/user-support/'+user.id);
        if (response.status === 200) {
            const data = await response.json()
            this.$set(user, "is_support", data[0])
        }
    }

    async save_user_is_support(user: IKeyCloakUser) {
        const saveData = {
            user_id: user.id,
            vals: [
                {
                    item: user.is_support
                }
            ]
        };
        try {
            await fetch('/api/um/save-user-support', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(saveData)
            });
        } catch (error) {
            this.makeToast('danger', 'Ошибка сохранения данных: ' + user.id, error.toString());
        }
    }

    private activateTab(id: number) {
        this.activeTab = id;
        console.log(this.$store.getters.user_uuid, this.$store.state._instanceCode)
    }

    protected get isHasUmAdminRole(): boolean {
        return store.getters.user.roles.includes("um_admin");
    }

    private async clone_user() {
        const mesRes = await this.showMsgBoxOne('Создать пользователя на основе образца?', {
            okTitle: 'Создать',
            cancelTitle: 'Не создавать',
        });
        if (mesRes) {
            try {
                const response = await fetch('/api-py/clone-user-data/'+this.sourceUser?.id+'/'+this.selectedUser?.id);
                if (response.status === 200) {
                    this.makeToast('success', 'Сообщение', 'Настройки скопированы');
                    this.activateTab(0);
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка копирования пользователя', error.toString());
            }
        } else {
        }
    }

    private async showMsgBoxOne(question: string, options: object) {
        const res = await this.$bvModal.msgBoxConfirm(question, options);
        return res;
    }

    private get userFiltered() {
        const res = [];
        for (const item of this.userList) {
            if (item.username.toString().includes(this.userFilter)) {
                res.push(item);
            }
            else if ((item.firstName) && (item.firstName.toString().includes(this.userFilter))) {
                res.push(item);
            }
            else if ((item.lastName) &&  (item.lastName.toString().includes(this.userFilter))) {
                res.push(item);
            }
        }
        return res
    }

    private async loadUsers() {
        try {
            const response = await fetch('/api-py/get-realm-users-fast/');
            this.userList = await response.json() as IKeyCloakUser;
            this.userList = this.userList.reverse();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки пользователей', error.toString());
        }
    }

    private async loadUsersCount() {
        try {
            const response = await fetch('/api-py/get-realm-users-count');
            this.realm_user_count = await response.json() as number;
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки пользователей', error.toString());
        }
    }

    protected selectUser(user: IKeyCloakUser) {
        this.selectedUser = user;
    }

    async getObl() {
        try {
            await fetch('/api-py/get-budget-obl/' + this.$store.state._instanceCode)
                .then(response => response.json())
                .then(json => {
                    this.oblRegion = json.obl;
                    // this.region = json.region;
                });

        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
        }
    }

    private async loadDictOperations() {
        try {
            const response = await fetch("/api/dict/user-operations");
            this.operations = (await response.json()) as IUserOperations;
            this.operations = [...this.operations];
            } catch (error) {
            this.makeToast(
                "danger",
                "Ошибка загрузки операция пользователя",
                error.toString()
            );
        }
    }

    public mounted() {
        this.loadUsers();
        this.getObl();
        this.loadDictOperations();
        this.loadUsersCount();
    }

    private getLabel(item: any):string {
        return `${item.username} — ${item.firstName} ${item.lastName||''}`
    }
}
