


































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import VueElementLoading from 'vue-element-loading';
import '../user-management.scss';
import {IKeyCloakUser, IEbkItem, IGu, IRegion} from '../user-management-int';
import BaseUserLink from '../base-user-link';

@Component({
    components: {
        'loading': VueElementLoading
    }
})
export default class WorkplaceUserData extends BaseUserLink {
    private guBase: any = [];
    private guList: any[] = [];
    private regionBase: any = [];
    private abpBase: any = [];
    // private guFilteredList: any[] = [];
    @Prop({default: "45"}) private oblRegion: string | undefined;
    private selectedUserGuList: any = [];
    private loading = false;
    private leftFilter = '';
    private rightFilter = '';
    private filterRegion = null;
    private filterAbp = null;


    @Watch('selectedUser')
    async onPropertyChanged(value: IKeyCloakUser, oldValue: IKeyCloakUser) {
        this.loading = true;
        if (oldValue !== null) {
            this.selectedUserGuList = [];
            this.guList = [...this.guBase];
            this.guList.sort((a, b) => (a.code > b.code) ? 1 : -1);
        }
        await this.selectUser(value);
        this.loading = false;
    }

    private get leftFilterGuList() {
        const res = [];
        for (const item of this.guList) {
            if (item.code.toString().includes(this.leftFilter)) {
                res.push(item);
            } else if (item.name_ru.toString().includes(this.leftFilter)) {
                res.push(item);
            }
        }
        return res;
    }


    private get rightFilterGuList() {
        const res = [];
        for (const item of this.selectedUserGuList) {
            if (item.code.toString().includes(this.rightFilter)) {
                res.push(item);
            } else if (item.name_ru.toString().includes(this.rightFilter)) {
                res.push(item);
            }
        }
        return res;
    }

    private async loadRegions(user: IKeyCloakUser) {
        try {
            const response = await fetch('/api-py/get-user-regions-by-obl/' + this.oblRegion+'/'+user?.id);
            this.regionBase = await response.json() as IRegion;
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки регионов', error.toString());
        }
    }

    private async loadRegions_old() {
        try {
            const response = await fetch('/api-py/get-regions-by-obl/' + this.oblRegion);
            this.regionBase = await response.json() as IRegion;
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки регионов', error.toString());
        }
    }

    private userLevels: string[] = [];
    get userLevelsList() {
        let result = '['
        for (const lv of this.userLevels) {
            result = result + lv + ','
        }
        if (result.substring(result.length - 1) == ',') {
            result = result.substring(0, result.length-1) + ']'
        }
        if (result = '[') {
            result = '[2,3,4]'
        }
        return result
    }

    private async loadUserLevels(user: IKeyCloakUser) {
        try {
            const response = await fetch('/api-py/get-user-budget-level-only/'+user?.id);
            this.userLevels = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки уровней пользователя', error.toString());
        }
    }

    private async loadABP(user: IKeyCloakUser) {
        try {
            const response = await fetch('/api-py/get-dict-ved-abp-by-budgetlevel-user/'+this.userLevelsList+'/'+user?.id);
            this.abpBase = await response.json() as IEbkItem;
            if (this.abpBase.length>0) {
                const firstAbp : IEbkItem = {
                    id: 0,
                    gr: 0,
                    abp: 0,
                    name_ru: 'Все АБП',
                    name_kk: 'Все АБП',
                    beg_date: '2022-01-01',
                    end_date: '2022-01-01'
                }
                this.abpBase.unshift(firstAbp);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки АБП', error.toString());
        }
    }

    private async loadABP_old() {
        try {
            const response = await fetch('/api-py/get-dict-ved-abp-by-budgetlevel/'+this.userLevelsList+'');
            this.abpBase = await response.json() as IEbkItem;
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки АБП', error.toString());
        }
    }

    private async loadGuFromBase() {
        try {
            if ((this.filterRegion !== null) && (this.filterAbp !== null) && (this.filterAbp !== 0)) {
                const response = await fetch('/api-py/dict-gu-by-region-abp/' + this.filterRegion + '/' + this.filterAbp);
                this.guBase = await response.json() as IGu;
                this.guList = [...this.guBase];
            }
            if ((this.filterRegion !== null) && (this.filterAbp == 0)) {
                const response = await fetch('/api-py/dict-gu-by-region/' + this.filterRegion + '/' + this.userLevels);
                this.guBase = await response.json() as IGu;
                this.guList = [...this.guBase];
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки данных ГУ', error.toString());
        }
    }

    public async selectUser(user: IKeyCloakUser) {
        this.selectedUser = user;
        await this.loadUserFuncGroup(user);
        if (this.selectedUser !== null) {
            // if (this.selectedUser.guList) {
            //     this.selectedUserGuList = [...this.selectedUser.guList];
            for (const item of this.selectedUserGuList) {
                for (const it of this.guList) {
                    if (it.code === item.code) {
                        const ind = this.guList.indexOf(it);
                        this.guList.splice(ind, 1);
                    }
                }
            }
            // }
        }
    }

    removeAllGu() {
        for (const abpListElement of this.selectedUserGuList) {
            this.guList.push(abpListElement)
        }
        this.guList.sort((a, b) => (a.code > b.code) ? 1 : -1);
        this.selectedUserGuList = []
        this.saveUserguData(this.selectedUser);
    }

    addAllGu() {
        for (const abpListElement of this.guList) {
            if (!this.selectedUserGuList.find((value: any) => value.id === abpListElement.id))  this.selectedUserGuList.push(abpListElement)
        }
        this.selectedUserGuList.sort((a: any, b: any) => (a.code > b.code) ? 1 : -1);
        this.guList = []
        this.saveUserguData(this.selectedUser);
    }


    private addGuToSelectedUser(gu: any, save: boolean) {
        let find = false;
        for (const g of this.selectedUserGuList) {
            if (g.code == gu.code) {
                find = true;
                break;
            }
        }
        if (!find) {
            this.selectedUserGuList.push(gu);
            const ind = this.guList.indexOf(gu);
            this.guList.splice(ind, 1);
            this.selectedUserGuList.sort((a: any, b: any) => (a.code > b.code) ? 1 : -1);
            // this.selectedUser.guList = [...this.selectedUserGuList];
        }
        if (save) {
            this.saveUserguData(this.selectedUser);
        }
    }

    private removeGuFromSelectedUser(gu: any, save: boolean) {
        gu.lead = false;
        const ind = this.selectedUserGuList.indexOf(gu);
        this.selectedUserGuList.splice(ind, 1);
        this.guList.push(gu);
        this.guList.sort((a, b) => (a.code > b.code) ? 1 : -1);
        if (save) {
            this.saveUserguData(this.selectedUser);
        }
    }
    
    private async saveUserguData(user: IKeyCloakUser) {
        const workplaceList: any = [];
        // массив выбраных ФГ для базы
        for (const item of this.selectedUserGuList) {
            const d = {
                workplace: item.code,
                lead: item.lead
            };
            workplaceList.push(d);
        }
        // объект для передачи
        if (user !== null) {
            const saveData = {
                user_id: user?.id,
                workplaceList: workplaceList
            };
            try {
                await fetch('/api-py/save-user-workplace', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(saveData)
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка сохранения данных: ' + user.id, error.toString());
            }
        }
    }

    private async loadGuLikeCodeName(templ: string) {
        if (templ.length > 4) {
            this.filterRegion = null;
            this.filterAbp = null;
            try {
                const guResponse = await fetch('/api-py/dict-gu-region-like-code-name/' + this.oblRegion + '/' + templ);
                this.guBase = await guResponse.json() as IGu;
                this.guList = [...this.guBase];
            } catch (error) {
                this.makeToast('danger', 'Ошибка поиска по маске : ' + templ, error.toString());
            }
        }
    }


    private async loadGuByCode(code: string) {
        const guResponse = await fetch('/api-py/dict-gu-by-code/' + code);
        return await guResponse.json() as IGu[];
    }

    private async loadUserFuncGroup(user: IKeyCloakUser) {
        if (user !== null)
            try {
                this.selectedUserGuList = [];
                const response = await fetch('/api-py/user-workplace-gu/' + user?.id);
                const data = await response.json();
                for (const item of data) {
                    const guData = await this.loadGuByCode(item.workplace);
                    for (const t of guData as any) {
                        if (item.workplace === t.code) {
                            t.lead = item.lead;
                            this.addGuToSelectedUser(t, false);
                        }
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки данных ГУ для : ' + user.id, error.toString());
            }
    }

    public async mounted() {
        await this.loadUserLevels(this.selectedUser);
        await this.loadABP(this.selectedUser);
        await this.loadRegions(this.selectedUser);
        await this.loadGuFromBase();
        if (this.selectedUser !== null) {
            this.loadUserFuncGroup(this.selectedUser);
        }
    }

}
