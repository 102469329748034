




























import {delay} from "lodash";
import {Vue, Prop, Component, Watch} from "vue-property-decorator";
import {IModule, IUserOperations} from "../user-management-int";
import VueElementLoading from 'vue-element-loading';
import BaseUserLink from "../base-user-link";
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { Ax } from '@/utils';

interface TreeNode {
    id: string;
    children?: TreeNode[];
}



@Component({
    components: {
        'loading': VueElementLoading,
        'treeselect': Treeselect
                }
    })
    export default class MultyCheck extends BaseUserLink {
    @Prop() private module!: IModule;

    private inner: string[] = [...(this.module.operations || [])];
    private saving = false;
    private normalizer(node: any) {
        return {
            id: node.id,
            label: node.name_ru,
            children: node.children
        };
    }
    private showModal(item: any) {
        this.selectedAppointment = item;
        this.show = !this.show;
    }
    private selectedAppointment = {}
    async onPropertyChanged() {
        for (const t of this.tree) {
            const ops: any = [];
            for (const op of (t.children as any[])) {
                ops.push(op.id);
            }
            const match = this.inner.find(el => el == t.id);
            if (match) {
                this.inner = [...ops, ...this.inner.filter(element => element != t.id)];
            }
        }
        const scope = this;
        scope.saving = true;
        this.save().then(response => {
            if (response.status >= 200 && response.status < 300) {
                scope.module.operations = [...scope.inner];
            } else {
                return Promise.reject(new Error(response.statusText))
            }
        }).catch((error: Error) => {
            this.makeToast(
                "danger",
                "Ошибка сохранения полномочий: " + this.module.name_ru,
                error.toString()
            );
            scope.inner = [...(scope.module.operations || [])];
        }).then(any => {
            scope.saving = false
        });
    }
   async getOperations() {
    const params = {
        code: this.module.code
    };
    try {
        const url = '/api-py/operations/';
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params) 
        });
        const result = await response.json();
        if (response.status === 200) {
            const tree = result.reduce((acc: any, item: any) => {
               
                if (!item.is_deleted) {
                    let parent = acc.find((node: any) => node.label === item.nameRu);
                    if (!parent) {
                        parent = { id: item.code, label: item.nameRu, children: [] };
                        acc.push(parent);
                    }

                    if (!item.is_deleted) {
                        parent.children.push({ id: item.operation, label: item.op_name, notEnabled: true });
                    }
                }
                return acc;
            }, []);
            
            this.tree = tree;

          
            for (const t of this.tree) {
                const ops: any = [];
                for (const op of (t.children as any[])) {
                    ops.push(op.id);
                }
                const match = ops.every((el: any) => this.inner.includes(el));
                if (match) {
                    this.inner = [...ops, ...this.inner.filter(element => !ops.includes(element))];
                }
            }
        }
    } catch (error) {
        console.error("Error fetching operations:", error);
    }
}

    private show = false
    private tree: TreeNode[] = []
    private getPlaceholder() {
        return this.inner.length + " выбрано";
    }

    async save() {
        const data = (JSON.parse(JSON.stringify(this.module)));
        data.id = data.baseId;
        data.operations = this.inner;
        return await fetch("/api/um/module/link/operations/save", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(data),
        });
    }
    private async mounted() {
    await this.getOperations()        
    }
}
